var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"preview rounded border border-secondary d-flex flex-column"},[_c('div',{staticClass:"flex-fill p-3"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-avatar',{staticClass:"mr-2",attrs:{"src":_vm.staff.avatar_url}}),_c('div',{staticClass:"align-self-center message px-2"},[_vm._v("Hello!")])],1)]),(_vm.content.selected)?_c('div',{staticClass:"menu border border-primary",style:({
      aspectRatio: ((_vm.content.size.width) + "/" + (_vm.content.size.height)),
    })},[_c('b-img',{staticClass:"img-fluid",attrs:{"center":"","src":_vm.imageUrl}}),(!_vm.isCustoming || _vm.content.name != 'custom')?_vm._l((_vm.content.areas),function(a,adx){return _c('button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(("accordion-" + adx)),expression:"`accordion-${adx}`"}],key:adx,staticClass:"\n          frame\n          d-flex\n          justify-content-center\n          align-items-center\n          position-absolute\n          h4\n          text-white\n          px-0\n        ",style:({
          left: (((a.bounds.x / _vm.content.size.width) * 100) + "%"),
          top: (((a.bounds.y / _vm.content.size.height) * 100) + "%"),
          width: (((a.bounds.width / _vm.content.size.width) * 100) + "%"),
          height: (((a.bounds.height / _vm.content.size.height) * 100) + "%"),
        })},[_c('span',[_vm._v(" "+_vm._s(["A", "B", "C", "D", "E", "F"][adx]))])])}):_vm._e(),(_vm.isCustoming)?[_c('div',{staticClass:"menu__custom"},[_c('canvas',{ref:"custom",staticClass:"position-absolute",staticStyle:{"top":"0"},attrs:{"width":_vm.content.size.width / _vm.scale,"height":_vm.content.size.height / _vm.scale},on:{"mousedown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }return _vm.customDown.apply(null, arguments)},"mouseup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }return _vm.customUp.apply(null, arguments)},"mousemove":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }return _vm.customMove.apply(null, arguments)}}}),_c('div',{staticClass:"h-100 w-100"},_vm._l((_vm.customize.items),function(i,idx){return _c('div',{key:idx,staticClass:"border border-primary menu__custom__box",style:({
              left: ((i.x) + "px"),
              top: ((i.y) + "px"),
              width: ((i.width) + "px"),
              height: ((i.height) + "px"),
            }),on:{"mousedown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }$event.preventDefault();return _vm.itemDown($event, i)},"mouseup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }$event.preventDefault();return _vm.itemUp.apply(null, arguments)},"mousemove":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }$event.preventDefault();return _vm.itemMove($event, i)}}},[_c('button',{staticClass:"\n                btn\n                px-1\n                py-0\n                text-white\n                position-absolute\n                menu__custom__remove\n              ",on:{"click":function($event){$event.preventDefault();return _vm.customize.items.splice(idx, 1)}}},[_c('i',{staticClass:"fa fa-remove m-0"})])])}),0)])]:_vm._e(),(_vm.content.name == 'custom' && !_vm.isCustoming)?_c('div',{staticClass:"menu__custom_edit rounded p-1"},[_c('button',{staticClass:"btn text-white",on:{"click":function () {
            _vm.isCustoming = true;
            _vm.customize.items = _vm.content.areas.map(function (x) {
              return {
                width: x.bounds.width / _vm.scale,
                height: x.bounds.height / _vm.scale,
                x: x.bounds.x / _vm.scale,
                y: x.bounds.y / _vm.scale,
              };
            });
          }}},[_c('i',{staticClass:"fa fa-pencil-square-o"})])]):_vm._e()],2):_vm._e(),(!_vm.isCustoming)?_c('button',{staticClass:"\n      border-0 border-top\n      bg-white\n      p-3\n      small\n      d-flex\n      justify-content-between\n    ",on:{"click":function($event){_vm.content.selected = !_vm.content.selected}}},[_vm._m(0),_c('div',[_vm._v(" "+_vm._s(_vm.content.chatBarText)+" "),_c('i',{class:("fa fa-angle-" + (_vm.content.selected ? 'down' : 'up')),attrs:{"aria-hidden":"true"}})]),_c('div')]):_c('button',{staticClass:"\n      border-0 border-top\n      p-3\n      small\n      bg-success\n      text-white text-center\n    ",on:{"click":_vm.customSave}},[_vm._v(" 套用自訂欄位 ")])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('i',{staticClass:"fa fa-keyboard-o",attrs:{"aria-hidden":"true"}})])}]

export { render, staticRenderFns }