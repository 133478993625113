<template>
  <div
    class="preview rounded border border-secondary d-flex flex-column"
  >
    <div class="flex-fill p-3">
      <div class="d-flex align-items-center">
        <b-avatar class="mr-2" :src="staff.avatar_url"></b-avatar>
        <div class="align-self-center message px-2">Hello!</div>
      </div>
    </div>
    <div
      v-if="content.selected"
      class="menu border border-primary"
      :style="{
        aspectRatio: `${content.size.width}/${content.size.height}`,
      }"
    >
      <b-img
        center
        :src="imageUrl"
        class="img-fluid"
      />
      <template v-if="!isCustoming || content.name != 'custom'">
        <button
          class="
            frame
            d-flex
            justify-content-center
            align-items-center
            position-absolute
            h4
            text-white
            px-0
          "
          v-for="(a, adx) in content.areas"
          :key="adx"
          :style="{
            left: `${(a.bounds.x / content.size.width) * 100}%`,
            top: `${(a.bounds.y / content.size.height) * 100}%`,
            width: `${
              (a.bounds.width / content.size.width) * 100
            }%`,
            height: `${
              (a.bounds.height / content.size.height) * 100
            }%`,
          }"
          v-b-toggle="`accordion-${adx}`"
        >
          <span> {{ ["A", "B", "C", "D", "E", "F"][adx] }}</span>
        </button>
      </template>
      <template v-if="isCustoming">
        <div class="menu__custom">
          <canvas
            ref="custom"
            class="position-absolute"
            style="top: 0"
            :width="content.size.width / scale"
            :height="content.size.height / scale"
            @mousedown.left="customDown"
            @mouseup.left="customUp"
            @mousemove.left="customMove"
          ></canvas>

          <div class="h-100 w-100">
            <div
              v-for="(i, idx) in customize.items"
              class="border border-primary menu__custom__box"
              :key="idx"
              :style="{
                left: `${i.x}px`,
                top: `${i.y}px`,
                width: `${i.width}px`,
                height: `${i.height}px`,
              }"
              @mousedown.left.prevent="itemDown($event, i)"
              @mouseup.left.prevent="itemUp"
              @mousemove.left.prevent="itemMove($event, i)"
            >
              <button
                class="
                  btn
                  px-1
                  py-0
                  text-white
                  position-absolute
                  menu__custom__remove
                "
                @click.prevent="customize.items.splice(idx, 1)"
              >
                <i class="fa fa-remove m-0"></i>
              </button>
            </div>
          </div>
        </div>
      </template>

      <div
        v-if="content.name == 'custom' && !isCustoming"
        class="menu__custom_edit rounded p-1"
      >
        <button
          class="btn text-white"
          @click="
            () => {
              isCustoming = true;
              customize.items = content.areas.map((x) => {
                return {
                  width: x.bounds.width / scale,
                  height: x.bounds.height / scale,
                  x: x.bounds.x / scale,
                  y: x.bounds.y / scale,
                };
              });
            }
          "
        >
          <i class="fa fa-pencil-square-o"></i>
        </button>
      </div>
    </div>
    <button
      class="
        border-0 border-top
        bg-white
        p-3
        small
        d-flex
        justify-content-between
      "
      @click="content.selected = !content.selected"
      v-if="!isCustoming"
    >
      <div><i class="fa fa-keyboard-o" aria-hidden="true"></i></div>
      <div>
        {{ content.chatBarText }}
        <i
          :class="`fa fa-angle-${
            content.selected ? 'down' : 'up'
          }`"
          aria-hidden="true"
        ></i>
      </div>
      <div></div>
    </button>
    <button
      class="
        border-0 border-top
        p-3
        small
        bg-success
        text-white text-center
      "
      v-else
      @click="customSave"
    >
      套用自訂欄位
    </button>
  </div>
</template>

<script>
import store from "@/store";
import _ from "lodash";

const scale = 3;

export default {
  props: {
    imageUrl: {
      type: [String, null]
    },
    content: {
      type: Object,
      required: true,
    },
    isCustoming: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      customize: {
        canvas: { x: 0, y: 0 },
        last_mouse: { x: 0, y: 0 },
        mouse: { x: 0, y: 0 },
        mousedown: false,
        prev: {
          x: 0,
          y: 0,
          width: 0,
          height: 0,
        },
        items: [],
        item_mousedown: false,
        item_last_pos: { x: 0, y: 0 },
        item_mouse: { x: 0, y: 0 },
      },
      scale,
      staff: store.state.auth.user,
    }
  },
  methods: {
    clearCustomItems() {
      this.customize.items = [];
    },
    customDown(e) {
      this.customize.canvas = {
        x: this.$refs.custom.getBoundingClientRect().x,
        y: this.$refs.custom.getBoundingClientRect().y,
      };

      this.customize.last_mouse = {
        x: parseInt(e.clientX - this.customize.canvas.x),
        y: parseInt(e.clientY - this.customize.canvas.y),
      };

      this.customize.mousedown = true;
    },
    customUp() {
      this.customize.mousedown = false;

      this.customize.items.push({
        x: this.customize.prev.x,
        y: this.customize.prev.y,
        width: this.customize.prev.width,
        height: this.customize.prev.height,
      });

      var ctx = this.$refs.custom.getContext("2d");

      ctx.clearRect(0, 0, this.$refs.custom.width, this.$refs.custom.height);

      // var ctxo = this.$refs.customOverlay.getContext("2d");
      // ctxo.strokeRect(
      //   this.customize.prev.x,
      //   this.customize.prev.y,
      //   this.customize.prev.width,
      //   this.customize.prev.height
      // );
    },
    customMove(e) {
      this.customize.mouse = {
        x: parseInt(e.clientX - this.customize.canvas.x),
        y: parseInt(e.clientY - this.customize.canvas.y),
      };

      var ctx = this.$refs.custom.getContext("2d");

      if (this.customize.mousedown) {
        ctx.clearRect(0, 0, this.$refs.custom.width, this.$refs.custom.height); //clear canvas
        ctx.beginPath();
        var width = this.customize.mouse.x - this.customize.last_mouse.x;
        var height = this.customize.mouse.y - this.customize.last_mouse.y;
        ctx.strokeStyle = "lightgreen";
        ctx.lineWidth = 2;
        ctx.strokeRect(
          this.customize.last_mouse.x,
          this.customize.last_mouse.y,
          width,
          height
        );

        this.customize.prev.x =
          this.customize.last_mouse.x + (width >= 0 ? 0 : width);
        this.customize.prev.y =
          this.customize.last_mouse.y + (height >= 0 ? 0 : height);
        this.customize.prev.width = Math.abs(width);
        this.customize.prev.height = Math.abs(height);
      }

      // console.log(
      //   `current:${this.customize.mouse.x},${this.customize.mouse.y}`,
      //   `last:${this.customize.last_mouse.x},${this.customize.last_mouse.y}`,
      //   `mousedown:${this.customize.mousedown}`
      // )
    },
    itemDown(e, item) {
      this.customize.item_mousedown = true;

      this.customize.item_last_pos = {
        x: parseInt(e.clientX - this.customize.canvas.x - item.x),
        y: parseInt(e.clientY - this.customize.canvas.y - item.y),
      };
    },
    itemUp() {
      this.customize.item_mousedown = false;
    },
    itemMove(e, item) {
      this.customize.item_mouse = {
        x: parseInt(e.clientX - this.customize.canvas.x),
        y: parseInt(e.clientY - this.customize.canvas.y),
      };

      if (this.customize.item_mousedown) {
        let x = this.customize.item_mouse.x - this.customize.item_last_pos.x;
        let y = this.customize.item_mouse.y - this.customize.item_last_pos.y;

        if (x >= 0 && x + item.width <= this.$refs.custom.width) {
          item.x = x;
        }
        if (y >= 0 && y + item.height <= this.$refs.custom.height) {
          item.y = y;
        }
      }
    },
    customSave() {
      const areas = _.cloneDeep(this.customize.items).map((x) => {
        return {
          action: {
            displayText: "",
            richMenuAliasId: null,
            type: "uri",
            uri: "",
          },
          bounds: {
            width: x.width * scale,
            height: x.height * scale,
            x: x.x * scale,
            y: x.y * scale,
          },
        };
      });

      this.$emit("custom-save", areas);
    },
  },
}
</script>

<style lang="scss" scoped>
.preview {
  background: #8cabd9;
  aspect-ratio: 9 / 16;
  overflow: hidden;
  width: 300px;

  .message {
    background-color: #f4f6f9;
    border-radius: 1rem;
    border-bottom-left-radius: 0;
    max-width: 450px;
  }

  .menu {
    background: #ccc;
    position: relative;

    .frame {
      background: none;

      border: solid 2px var(--primary);
      //border:none;
    }

    &__custom {
      canvas {
        cursor: crosshair;
      }
      &__box {
        border-width: 2px !important;
        position: absolute;
        cursor: move;
      }
      &__remove {
        right: 0;
        top: 0;
        border-radius: 0;
        background: var(--primary);
      }
    }

    &__custom_edit {
      background: rgba($color: #000000, $alpha: 0.5);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: none;
    }

    &:hover {
      .menu__custom_edit {
        display: inline-block;
      }
    }
  }
}
</style>
