<template>
  <b-overlay v-if="menu.content" :show="isLoading">
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item>LINE 主控台</b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'RichMenu' }"
              >圖文選單</b-breadcrumb-item
            >
            <b-breadcrumb-item active>{{ title }}</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row mb-2 align-items-center">
        <div class="col-12 col-xl-2">
          <h4 class="font-weight-bold">{{ title }}</h4>
        </div>
      </div>

      <h5>基本設定</h5>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label text-right">* 標題</label>
        <div class="col-sm-10">
          <b-form-input
            type="text"
            class="form-control"
            placeholder="輸入標題"
            :state="v$.menu.title.$error ? false : null"
            maxlength="100"
            required
            v-model="menu.title"
            :disabled="isReadonly"
          />
          <b-form-invalid-feedback :state="!v$.menu.title.$error">
            此欄位為必填
          </b-form-invalid-feedback>
        </div>
      </div>

      <div class="form-group form-row">
        <label class="col-sm-2 col-form-label text-right">* 開始時間</label>
        <div class="col-sm-10">
          <div class="row">
            <div class="col-sm-3">
              <datepicker
                placeholder="日期"
                v-model="menu.start_at.date"
                bootstrap-styling
                format="yyyy-MM-dd"
                :language="zh"
                :input-class="v$.menu.start_at.date.$error ? 'is-invalid' : ''"
                :disabledDates="{ to: new Date(Date.now() - 8640000) }"
                :disabled="isReadonly"
              ></datepicker>
              <b-form-invalid-feedback :state="!v$.menu.start_at.date.$error">
                此欄位為必填
              </b-form-invalid-feedback>
            </div>
            <div class="col-sm-3 timepicker">
              <vue-timepicker
                :input-class="`${
                  v$.menu.start_at.time.HH.$error ||
                  v$.menu.start_at.time.mm.$error
                    ? 'is-invalid'
                    : ''
                } form-control`"
                v-model="menu.start_at.time"
                hide-clear-button
                :minute-interval="10"
                :disabled="isReadonly"
              ></vue-timepicker>
              <b-form-invalid-feedback
                :state="
                  !v$.menu.start_at.time.HH.$error &&
                  !v$.menu.start_at.time.mm.$error
                "
              >
                此欄位為必填
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group form-row">
        <label class="col-sm-2 col-form-label text-right">* 結束時間</label>
        <div class="col-sm-10">
          <div class="row">
            <div class="col-sm-3">
              <datepicker
                placeholder="日期"
                v-model="menu.end_at.date"
                bootstrap-styling
                format="yyyy-MM-dd"
                class="flex-fill"
                :language="zh"
                :input-class="v$.menu.end_at.date.$error ? 'is-invalid' : ''"
                :disabledDates="{ to: new Date(Date.now() - 8640000) }"
                :disabled="isReadonly"
              ></datepicker>
              <b-form-invalid-feedback :state="!v$.menu.end_at.date.$error">
                此欄位為必填
              </b-form-invalid-feedback>
              <b-form-invalid-feedback :state="!v$.endAt.$error">
                開始時間必須早於結束時間
              </b-form-invalid-feedback>
            </div>
            <div class="col-sm-3 timepicker">
              <vue-timepicker
                :input-class="`${
                  v$.menu.end_at.time.HH.$error || v$.menu.end_at.time.mm.$error
                    ? 'is-invalid'
                    : ''
                } form-control`"
                v-model="menu.end_at.time"
                hide-clear-button
                :minute-interval="10"
                :disabled="isReadonly"
              ></vue-timepicker>
              <b-form-invalid-feedback
                :state="
                  !v$.menu.end_at.time.HH.$error &&
                  !v$.menu.end_at.time.mm.$error
                "
              >
                此欄位為必填
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label text-right pr-0"
          ><span v-b-tooltip.hover title="初始會看到的選單"
            >預設選單</span
          ></label
        >
        <div class="col-sm-10">
          <b-form-radio-group
            class="mt-2"
            v-model="menu.set_default_when_publish"
            :options="[
              { text: '是', value: true },
              { text: '否', value: false },
            ]"
            :disabled="isReadonly"
          ></b-form-radio-group>
        </div>
      </div>

      <hr />

      <h5>內容設定</h5>

      <div class="d-flex">
        <div class="flex-fill">
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">版型</label>
            <div class="col-sm-10">
              <b-button variant="outline-primary" v-b-modal.modal-template :disabled="isReadonly"
                >選擇</b-button
              >

              <b-modal
                v-if="menu.content"
                id="modal-template"
                title="選擇版型"
                class="modal-template"
                centered
                hide-footer
                size="md"
              >
                <div class="h5">大型</div>
                <div>適合顯示版面較大或項目較多的圖文選單。</div>
                <small
                  >圖片尺寸建議：2500px × 1686px ／ 1200p × 810px ／ 800px ×
                  540px</small
                >
                <div class="d-flex flex-wrap justify-content-start mt-3">
                  <div
                    class="template template-big p-2"
                    v-for="(t, tdx) in menuBigTemplate"
                    :key="tdx"
                  >
                    <button
                      :class="`btn btn-link border border-${
                        templateIndex == tdx ? 'success' : 'secondary'
                      } rounded-0`"
                      @click="switchTemplate(t, tdx)"
                    >
                      <template v-if="t.areas.length > 0">
                        <div
                          :class="`action border border-${
                            templateIndex == tdx ? 'success' : 'secondary'
                          }`"
                          v-for="(a, adx) in t.areas"
                          :key="adx"
                          :style="{
                            left: `${(a.bounds.x / t.size.width) * 100}%`,
                            top: `${(a.bounds.y / t.size.height) * 100}%`,
                            width: `${(a.bounds.width / t.size.width) * 100}%`,
                            height: `${
                              (a.bounds.height / t.size.height) * 100
                            }%`,
                          }"
                        ></div>
                      </template>
                      <template v-else>
                        <div
                          :class="`action border border-${
                            templateIndex == tdx ? 'success' : 'secondary'
                          }`"
                          class="
                            d-flex
                            justify-content-center
                            align-items-center
                          "
                          :style="{
                            left: 0,
                            top: 0,
                            width: '100%',
                            height: '100%',
                          }"
                        >
                          自訂
                        </div>
                      </template>
                    </button>
                  </div>
                </div>

                <div class="h5 mt-3">小型</div>
                <div>適合顯示版面較小或項目較少的圖文選單。</div>
                <small
                  >圖片尺寸建議：2500px × 843px ／ 1200p × 405px ／ 800px ×
                  270px</small
                >
                <div class="d-flex flex-wrap justify-content-start mt-3">
                  <div
                    class="template template-small p-2"
                    v-for="(t, tdx) in menuSmallTemplate"
                    :key="tdx"
                  >
                    <button
                      :class="`btn btn-link border border-${
                        templateIndex == tdx + menuBigTemplate.length
                          ? 'success'
                          : 'secondary'
                      } rounded-0`"
                      @click="switchTemplate(t, tdx + menuBigTemplate.length)"
                    >
                      <template v-if="t.areas.length > 0">
                        <div
                          :class="`action border border-${
                            templateIndex == tdx + menuBigTemplate.length
                              ? 'success'
                              : 'secondary'
                          }`"
                          v-for="(a, adx) in t.areas"
                          :key="adx"
                          :style="{
                            left: `${(a.bounds.x / t.size.width) * 100}%`,
                            top: `${(a.bounds.y / t.size.height) * 100}%`,
                            width: `${(a.bounds.width / t.size.width) * 100}%`,
                            height: `${
                              (a.bounds.height / t.size.height) * 100
                            }%`,
                          }"
                        ></div>
                      </template>
                      <template v-else>
                        <div
                          :class="`action border border-${
                            templateIndex == tdx ? 'success' : 'secondary'
                          }`"
                          class="
                            d-flex
                            justify-content-center
                            align-items-center
                          "
                          :style="{
                            left: 0,
                            top: 0,
                            width: '100%',
                            height: '100%',
                          }"
                        >
                          自訂
                        </div>
                      </template>
                    </button>
                  </div>
                </div>
              </b-modal>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">圖片</label>
            <div class="col-sm-10 image-selector">
              <div
                class="image"
                v-if="imageFile || menu.image_url"
                :style="{
                  aspectRatio: `${menu.content.size.width}/${menu.content.size.height}`,
                }"
              >
                <img
                  class="img-fluid"
                  :src="imageFile != null ? imageFile : menu.image_url"
                />
                <button
                  class="btn btn-danger"
                  type="button"
                  @click="imageFile = menu.image_url = null"
                >
                  <i class="fa fa-times m-0" />
                </button>
              </div>
              <div
                class="selector"
                :style="{
                  aspectRatio: `${menu.content.size.width}/${menu.content.size.height}`,
                }"
                v-else
              >
                <ImageSelector
                  @input="selectImage"
                  :aspect-ratio="
                    menu.content.size.width / menu.content.size.height
                  "
                  class="
                    border
                    h-100
                    d-flex
                    justify-content-center
                    align-items-center
                  "
                  :class="{ 'border-danger': v$.imageFile.$error }"
                  :show-error="v$.imageFile.$error"
                >
                  <template #trigger="{ openFileSelector }">
                    <button class="btn s-trigger-btn" @click="openFileSelector">
                      <i class="fa fa-plus"></i> 圖片
                    </button>
                  </template>
                  <template #content>
                    <small class="text-center mb-2"
                      >｜檔案格式：JPG、JPEG、PNG｜檔案大小：1MB 以下</small
                    >
                  </template>
                </ImageSelector>
                <b-form-invalid-feedback :state="!v$.imageFile.$error">
                  請選取圖片
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="col-form-label">編輯欄位
              <small v-if="menu.content.name == 'custom'" :class="!isCustoming && 'text-muted'">請先點選預覽下方 套用自訂欄位 按鈕，點擊完成後可進行內容編輯</small>
            </label>
            <b-card
              v-for="(i, idx) in menu.content.areas"
              class="mb-2 shadow-none"
              no-body
              :key="idx"
            >
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  block
                  v-b-toggle="`accordion-${idx}`"
                  variant="link"
                  class="
                    text-left
                    d-flex
                    justify-content-between
                    text-decoration-none
                  "
                >
                  {{ ["A", "B", "C", "D", "E", "F"][idx] }}

                  <i class="fa" aria-hidden="true"></i>
                </b-button>
              </b-card-header>
              <b-collapse
                :id="`accordion-${idx}`"
                visible
                accordion="accordion"
              >
                <b-card-body>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label text-right"
                      >類型</label
                    >
                    <div class="col-sm-10">
                      <b-select v-model="i.action.type" :disabled="isReadonly">
                        <b-select-option value="null">請選擇</b-select-option>
                        <b-select-option value="uri">連結</b-select-option>
                        <b-select-option value="message"
                          >文字訊息</b-select-option
                        >
                        <b-select-option value="richmenuswitch"
                          >切換圖文選單</b-select-option
                        >
                        <b-select-option value="tel">電話</b-select-option>
                      </b-select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="offset-sm-2 col-sm-10">
                      <template v-if="i.action.type == 'uri'">
                        <b-input
                          type="url"
                          class="form-control"
                          placeholder="輸入網址"
                          required
                          v-model="i.action.uri"
                          :state="
                            v$.menu.content.areas.$dirty &&
                            v$.menu.content.areas.$each.$response.$data[idx]
                              .action.$error
                              ? false
                              : null
                          "
                          :disabled="isReadonly"
                        />
                        <b-form-invalid-feedback
                          :state="
                            v$.menu.content.areas.$dirty &&
                            v$.menu.content.areas.$each.$response.$data[idx]
                              .action.$error
                              ? false
                              : null
                          "
                        >
                          請填入正確連結
                        </b-form-invalid-feedback>
                      </template>
                      <template v-if="i.action.type == 'message'">
                        <b-input
                          type="text"
                          class="form-control"
                          placeholder="輸入文字"
                          required
                          v-model="i.action.text"
                          :state="
                            v$.menu.content.areas.$dirty &&
                            v$.menu.content.areas.$each.$response.$data[idx]
                              .action.$error
                              ? false
                              : null
                          "
                          :disabled="isReadonly"
                        />
                        <b-form-invalid-feedback
                          :state="
                            v$.menu.content.areas.$dirty &&
                            v$.menu.content.areas.$each.$response.$data[idx]
                              .action.$error
                              ? false
                              : null
                          "
                        >
                          請填入文字，且長度小於 300
                        </b-form-invalid-feedback>
                      </template>
                      <template v-if="i.action.type == 'richmenuswitch'">
                        <b-select v-model="i.action.data" :options="richMenus" :disabled="isReadonly">
                          <template #first>
                            <b-form-select-option :value="null" :disabled="isReadonly"
                              >-- 請選擇 --</b-form-select-option
                            >
                          </template>
                        </b-select>
                        <b-form-invalid-feedback
                          :state="
                            v$.menu.content.areas.$dirty &&
                            v$.menu.content.areas.$each.$response.$data[idx]
                              .action.$error
                              ? false
                              : null
                          "
                        >
                          請選擇圖文選單
                        </b-form-invalid-feedback>
                      </template>
                      <template v-if="i.action.type == 'tel'">
                        <b-input
                          type="text"
                          class="form-control"
                          placeholder="輸入電話"
                          required
                          v-model="i.action.phoneNumber"
                          :state="
                            v$.menu.content.areas.$dirty &&
                            v$.menu.content.areas.$each.$response.$data[idx]
                              .action.$error
                              ? false
                              : null
                          "
                          :disabled="isReadonly"
                        />
                        <b-form-invalid-feedback
                          :state="
                            v$.menu.content.areas.$dirty &&
                            v$.menu.content.areas.$each.$response.$data[idx]
                              .action.$error
                              ? false
                              : null
                          "
                        >
                          請填入電話
                        </b-form-invalid-feedback>
                      </template>
                    </div>
                  </div>
                  <div
                    v-if="i.action.type == 'uri' || i.action.type == 'tel'"
                    class="form-group row"
                  >
                    <label class="col-sm-2 col-form-label text-right"
                      >動作標籤</label
                    >
                    <div class="col-sm-10">
                      <b-textarea
                        v-model="i.action.displayText"
                        rows="6"
                        placeholder="輸入動作標籤的說明"
                        :state="
                          v$.menu.content.areas.$dirty &&
                          v$.menu.content.areas.$each.$response.$data[idx]
                            .action.$error
                            ? false
                            : null
                        "
                        :disabled="isReadonly"
                      >
                      </b-textarea>
                      <b-form-invalid-feedback
                        :state="
                          v$.menu.content.areas.$dirty &&
                          v$.menu.content.areas.$each.$response.$data[idx]
                            .action.$error
                            ? false
                            : null
                        "
                      >
                        此欄位為必填
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>

          <div class="h5 mt-3">設定選單列</div>

          <div class="form-group row">
            <div class="col-sm-2 text-right">
              <label class="col-form-label d-block">選單列顯示文字</label>
              <small class="text-muted text-left"
                >顯示於圖表下方顯示之文字</small
              >
            </div>
            <b-form-group class="col-sm-10" :disabled="isReadonly">
              <b-form-radio
                v-model="customChatbar"
                :value="false"
                @change="menu.content.chatBarText = '選單'"
                >選單</b-form-radio
              >
              <div class="d-flex align-items-center">
                <b-form-radio
                  v-model="customChatbar"
                  :value="true"
                  class="text-nowrap mr-2"
                  @change="menu.content.chatBarText = ''"
                  >自訂文字</b-form-radio
                >
                <b-form-input
                  type="text"
                  class="form-control"
                  placeholder="自訂文字"
                  maxlength="14"
                  :disabled="!customChatbar || isReadonly"
                  v-model="menu.content.chatBarText"
                />
              </div>
            </b-form-group>
          </div>

          <div class="form-group row">
            <div class="col-sm-2 text-right">
              <label class="col-form-label d-block">預設顯示方式</label>
              <small class="text-muted text-left"
                >進入官方帳號時是否顯示選單</small
              >
            </div>
            <b-form-group class="col-sm-10" :disabled="isReadonly">
              <b-form-radio
                v-model="menu.content.selected"
                :value="true"
                class="py-2"
                >顯示</b-form-radio
              >
              <b-form-radio
                v-model="menu.content.selected"
                :value="false"
                class="py-2"
                >隱藏</b-form-radio
              >
            </b-form-group>
          </div>
        </div>
        <div class="ml-4">
          <div>預覽</div>
          <Preview
            ref="preview"
            :image-url="imageFile || menu.image_url"
            :content="menu.content"
            :is-customing="isCustoming"
            @custom-save="customSave"
          ></Preview>
        </div>
      </div>

      <div class="d-flex">
        <div class="flex-fill d-flex justify-content-center">
          <b-button
            @click="handleCancel"
            variant="outline-danger"
            class="mr-3"
            >返回</b-button
          >
          <b-button
            v-if="!isReadonly"
            @click="save('')"
            variant="success"
            class="mr-3"
            >{{ saveTitle }}</b-button
          >
          <b-button
            v-if="showSaveAndSetSchedule"
            @click="save('set-schedule')"
            variant="success"
            class="mr-3"
            >預約上線</b-button
          >
          <b-button
            v-if="showSaveAndPublish"
            @click="save('publish')"
            variant="success"
            class="mr-3"
            >立即上線</b-button
          >
        </div>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapGetters } from "vuex";
import richMenuApi from "@/apis/richmenu";
import Datepicker from "vuejs-datepicker";
import { zh } from "vuejs-datepicker/dist/locale";
import format from "date-fns/format";
import VueTimepicker from "vue2-timepicker";
import _ from "lodash";
import useVuelidate from "@vuelidate/core";
import { required, helpers, requiredIf } from "@vuelidate/validators";
import ImageSelector from "@/components/ImageSelector";
import imageMixin from "@/mixins/uploadImage";
import { RICH_MENU_STATUS } from "@/consts";
import Preview from "@/components/Dashboard/OA/RichMenu/Preview";

const basicAction = {
  type: "uri",
  displayText: "",
  uri: "",
  richMenuAliasId: null,
};

const menuBigTemplate = [
  {
    name: "",
    chatBarText: "選單",
    selected: true,
    size: {
      width: 900,
      height: 600,
    },
    areas: [
      {
        action: _.clone(basicAction),
        bounds: {
          x: 0,
          y: 0,
          width: 300,
          height: 300,
        },
      },
      {
        action: _.clone(basicAction),
        bounds: {
          x: 300,
          y: 0,
          width: 300,
          height: 300,
        },
      },
      {
        action: _.clone(basicAction),
        bounds: {
          x: 600,
          y: 0,
          width: 300,
          height: 300,
        },
      },
      {
        action: _.clone(basicAction),
        bounds: {
          x: 0,
          y: 300,
          width: 300,
          height: 300,
        },
      },
      {
        action: _.clone(basicAction),
        bounds: {
          x: 300,
          y: 300,
          width: 300,
          height: 300,
        },
      },
      {
        action: _.clone(basicAction),
        bounds: {
          x: 600,
          y: 300,
          width: 300,
          height: 300,
        },
      },
    ],
  },
  {
    name: "",
    chatBarText: "選單",
    selected: true,
    size: {
      width: 900,
      height: 600,
    },
    areas: [
      {
        action: _.clone(basicAction),
        bounds: {
          x: 0,
          y: 0,
          width: 450,
          height: 300,
        },
      },
      {
        action: _.clone(basicAction),
        bounds: {
          x: 450,
          y: 0,
          width: 450,
          height: 300,
        },
      },
      {
        action: _.clone(basicAction),
        bounds: {
          x: 0,
          y: 300,
          width: 450,
          height: 300,
        },
      },
      {
        action: _.clone(basicAction),
        bounds: {
          x: 450,
          y: 300,
          width: 450,
          height: 300,
        },
      },
    ],
  },
  {
    name: "",
    chatBarText: "選單",
    selected: true,
    size: {
      width: 900,
      height: 600,
    },
    areas: [
      {
        action: _.clone(basicAction),
        bounds: {
          x: 0,
          y: 0,
          width: 900,
          height: 300,
        },
      },
      {
        action: _.clone(basicAction),
        bounds: {
          x: 0,
          y: 300,
          width: 300,
          height: 300,
        },
      },
      {
        action: _.clone(basicAction),
        bounds: {
          x: 300,
          y: 300,
          width: 300,
          height: 300,
        },
      },
      {
        action: _.clone(basicAction),
        bounds: {
          x: 600,
          y: 300,
          width: 300,
          height: 300,
        },
      },
    ],
  },
  {
    name: "",
    chatBarText: "選單",
    selected: true,
    size: {
      width: 900,
      height: 600,
    },
    areas: [
      {
        action: _.clone(basicAction),
        bounds: {
          x: 0,
          y: 0,
          width: 600,
          height: 600,
        },
      },
      {
        action: _.clone(basicAction),
        bounds: {
          x: 600,
          y: 0,
          width: 300,
          height: 300,
        },
      },
      {
        action: _.clone(basicAction),
        bounds: {
          x: 600,
          y: 300,
          width: 300,
          height: 300,
        },
      },
    ],
  },
  {
    name: "",
    chatBarText: "選單",
    selected: true,
    size: {
      width: 900,
      height: 600,
    },
    areas: [
      {
        action: _.clone(basicAction),
        bounds: {
          x: 0,
          y: 0,
          width: 900,
          height: 300,
        },
      },
      {
        action: _.clone(basicAction),
        bounds: {
          x: 0,
          y: 300,
          width: 900,
          height: 300,
        },
      },
    ],
  },
  {
    name: "",
    chatBarText: "選單",
    selected: true,
    size: {
      width: 900,
      height: 600,
    },
    areas: [
      {
        action: _.clone(basicAction),
        bounds: {
          x: 0,
          y: 0,
          width: 450,
          height: 600,
        },
      },
      {
        action: _.clone(basicAction),
        bounds: {
          x: 450,
          y: 0,
          width: 450,
          height: 600,
        },
      },
    ],
  },
  {
    name: "",
    chatBarText: "選單",
    selected: true,
    size: {
      width: 900,
      height: 600,
    },
    areas: [
      {
        action: _.clone(basicAction),
        bounds: {
          x: 0,
          y: 0,
          width: 900,
          height: 600,
        },
      },
    ],
  },
  {
    name: "custom",
    chatBarText: "選單",
    selected: true,
    size: {
      width: 900,
      height: 600,
    },
    areas: [],
  },
];

const menuSmallTemplate = [
  {
    name: "",
    chatBarText: "選單",
    selected: true,
    size: {
      width: 900,
      height: 300,
    },
    areas: [
      {
        action: _.clone(basicAction),
        bounds: {
          x: 0,
          y: 0,
          width: 300,
          height: 300,
        },
      },
      {
        action: _.clone(basicAction),
        bounds: {
          x: 300,
          y: 0,
          width: 300,
          height: 300,
        },
      },
      {
        action: _.clone(basicAction),
        bounds: {
          x: 600,
          y: 0,
          width: 300,
          height: 300,
        },
      },
    ],
  },
  {
    name: "",
    chatBarText: "選單",
    selected: true,
    size: {
      width: 900,
      height: 300,
    },
    areas: [
      {
        action: _.clone(basicAction),
        bounds: {
          x: 0,
          y: 0,
          width: 300,
          height: 300,
        },
      },
      {
        action: _.clone(basicAction),
        bounds: {
          x: 300,
          y: 0,
          width: 600,
          height: 300,
        },
      },
    ],
  },
  {
    name: "",
    chatBarText: "選單",
    selected: true,
    size: {
      width: 900,
      height: 300,
    },
    areas: [
      {
        action: _.clone(basicAction),
        bounds: {
          x: 0,
          y: 0,
          width: 600,
          height: 300,
        },
      },
      {
        action: _.clone(basicAction),
        bounds: {
          x: 600,
          y: 0,
          width: 300,
          height: 300,
        },
      },
    ],
  },
  {
    name: "",
    chatBarText: "選單",
    selected: true,
    size: {
      width: 900,
      height: 300,
    },
    areas: [
      {
        action: _.clone(basicAction),
        bounds: {
          x: 0,
          y: 0,
          width: 450,
          height: 300,
        },
      },
      {
        action: _.clone(basicAction),
        bounds: {
          x: 450,
          y: 0,
          width: 450,
          height: 300,
        },
      },
    ],
  },
  {
    name: "",
    chatBarText: "選單",
    selected: true,
    size: {
      width: 900,
      height: 300,
    },
    areas: [
      {
        action: _.clone(basicAction),
        bounds: {
          x: 0,
          y: 0,
          width: 900,
          height: 300,
        },
      },
    ],
  },
  {
    name: "custom",
    chatBarText: "選單",
    selected: true,
    size: {
      width: 900,
      height: 300,
    },
    areas: [],
  },
];

const actionValid = (value) => {
  const isValidUrl = (urlString) => {
    var urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
  };

  switch (value.type) {
    case "uri":
      return (
        helpers.req(value.displayText) &&
        helpers.req(value.uri) &&
        isValidUrl(value.uri)
      );
    case "message":
      return helpers.req(value.text) && value.text.length <= 300;
    case "richmenuswitch":
      return helpers.req(value.data);
    case "tel":
      return helpers.req(value.phoneNumber);
    default:
      return false;
  }
};

export default {
  setup: () => ({ v$: useVuelidate() }),
  mixins: [imageMixin],
  components: { Datepicker, VueTimepicker, ImageSelector, Preview },
  validations() {
    //let textRule = {};

    const localRules = {
      menu: {
        title: { required },
        start_at: {
          date: { required },
          time: { HH: { required }, mm: { required } },
        },
        end_at: {
          date: { required },
          time: { HH: { required }, mm: { required } },
        },
        content: {
          areas: {
            $each: helpers.forEach({
              action: { actionValid },
            }),
          },
        },
      },
      endAt: {
        minValue: (value) => {
          return value > this.startAt;
        },
      },
      imageFile: {
        required: requiredIf(function () {
          return this.menu.image_url == null;
        }),
      },
    };

    return localRules;
  },
  data() {
    return {
      isCustoming: false,
      isLoading: false,
      zh,
      templateIndex: 0,
      menu: {
        title: "",
        start_at: {
          date: null,
          time: { HH: null, mm: null },
        },
        end_at: {
          date: null,
          time: { HH: null, mm: null },
        },
        set_default_when_publish: false,
        image_url: null,
        outer_code: null,
        status: 0,
        content: null,
      },
      richMenus: [],
      menuBigTemplate: menuBigTemplate,
      menuSmallTemplate: menuSmallTemplate,
      customChatbar: false,
      imageFile: null,
    };
  },

  computed: {
    ...mapGetters("route", ["routeQuery"]),
    startAt() {
      let startAt = new Date(this.menu.start_at.date);
      startAt.setHours(this.menu.start_at.time.HH);
      startAt.setMinutes(this.menu.start_at.time.mm);

      return `${format(startAt, "yyyy-MM-dd")}T${format(startAt, "HH:mm:ss")}`;
    },
    endAt() {
      let endAt = new Date(this.menu.end_at.date);
      endAt.setHours(this.menu.end_at.time.HH);
      endAt.setMinutes(this.menu.end_at.time.mm);

      return `${format(endAt, "yyyy-MM-dd")}T${format(endAt, "HH:mm:ss")}`;
    },
    title() {
      switch (this.$route.name) {
        case "RichMenuCreate":
          return "新增訊息";
        case "RichMenuShow":
          return "檢視訊息";
        case "RichMenuEdit":
          return "編輯訊息";
      }
      return "";
    },
    isReadonly() {
      return this.$route.name === "RichMenuShow";
    },
    isEditing() {
      return this.$route.name === "RichMenuEdit";
    },
    saveTitle() {
      return this.menu.status == RICH_MENU_STATUS.PUBLISHED
        ? "更新"
        : "儲存草稿";
    },
    showSaveAndSetSchedule() {
      return (
        !this.isReadonly &&
        this.menu.status == RICH_MENU_STATUS.DRAFT &&
        this.$permissions.has(this.$permissions.consts.LINE_MENU_MODIFY)
      );
    },
    showSaveAndPublish() {
      return (
        !this.isReadonly &&
        [RICH_MENU_STATUS.DRAFT, RICH_MENU_STATUS.RESERVE].includes(
          this.menu.status
        ) &&
        this.$permissions.has(this.$permissions.consts.LINE_MENU_MODIFY)
      );
    },
  },
  mounted() {
    if (this.$route.params.menuID) {
      this.fetchMenu();
    } else if (this.$route.query.copyFrom) {
      this.fetchMenuFromCopy();
    } else {
      this.menu.content = _.cloneDeep(menuBigTemplate[0]);
      this.templateIndex = 0;
    }
    this.fetchPublishRichMenus();
  },

  methods: {
    async fetchMenu() {
      this.isFetch = true;

      try {
        const { data } = await richMenuApi.getRichMenu(
          this.$route.params.menuID
        );

        this.setMenu(data);
      } catch (error) {
        console.log("error", error);
      }
      this.isFetch = false;
    },
    setMenu(data) {
      this.menu.title = data.data.title;
      this.menu.status = data.data.status;
      this.menu.set_default_when_publish = data.data.set_default_when_publish;

      const startAt = new Date(data.data.start_at);
      this.menu.start_at.date = startAt;
      this.menu.start_at.time.HH = format(startAt, "HH");
      this.menu.start_at.time.mm = format(startAt, "mm");

      const endAt = new Date(data.data.end_at);
      this.menu.end_at.date = endAt;
      this.menu.end_at.time.HH = format(endAt, "HH");
      this.menu.end_at.time.mm = format(endAt, "mm");

      this.menu.image_url = data.data.image_url;
      this.menu.content = data.data.content;
      //this.menu.is_selected = data.data.is_selected;
      //this.menu.chatbar_text = data.data.chatbar_text;

      this.menu.content.areas.forEach((area) => {
        if (area.action.type == "uri" && area.action.uri.startsWith("tel:")) {
          area.action.type = "tel";
        }
      });

      this.customChatbar = this.menu.content.chatBarText != "選單";
    },
    async fetchMenuFromCopy() {
      this.isFetch = true;

      try {
        const { data } = await richMenuApi.getRichMenu(
          this.$route.query.copyFrom
        );

        this.setMenu(data);
        this.menu.status = RICH_MENU_STATUS.DRAFT;
      } catch (error) {
        console.log("error", error);
      }
      this.isFetch = false;
    },
    async fetchPublishRichMenus() {
      const { data } = await richMenuApi.getRichMenus({
        statuses: [0, 1, 2],
        is_all: true,
      });
      this.richMenus = data.data.map((item) => {
        return {
          aliasId: item.alias_id,
          text: item.title,
          value: item.id,
        };
      });
    },

    switchTemplate(template, idx) {
      this.$swal({
        title: "您確定切換版型嗎？",
        text: "您的資料將不會儲存，是否確認切換？",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "確定",
        cancelButtonText: "取消",
      }).then((result) => {
        if (result.value) {
          this.templateIndex = idx;

          let temp = _.cloneDeep(template);

          temp.baseUrl = this.menu.content.baseUrl;

          this.menu.content = temp;

          //清除圖片
          this.imageFile = this.menu.image_url = null;

          this.$bvModal.hide("modal-template");

          if (temp.name == "custom") {
            this.isCustoming = true;
          } else {
            this.isCustoming = false;
          }

          this.$refs.preview.clearCustomItems();
        } else {
          return;
        }
      });
    },

    selectImage(image) {
      this.imageFile = image;
    },

    async save(nextAction) {
      const result = await this.v$.$validate();
      if (!result) return;

      const pass = await this.showConfirmSwal(nextAction);
      if (!pass) return;

      //Image
      if (this.imageFile) {
        const imgUrl = await this.uploadByBase64(this.imageFile);
        if (!imgUrl) {
          return;
        }
        this.menu.image_url = imgUrl;
      }

      let payload = _.cloneDeep(this.menu);

      payload.start_at = this.startAt;
      payload.end_at = this.endAt;
      payload.content.name = payload.title;
      payload.is_selected = this.menu.content.selected;
      payload.chatbar_text = this.menu.content.chatBarText;
      payload.content.areas.forEach((area) => {
        if (area.action.type == "richmenuswitch") {
          const richmenu = this.richMenus.find(
            (richmenu) => richmenu.value == area.action.data
          );
          area.action.richMenuAliasId = richmenu.aliasId;
        }
        if (area.action.type == "tel") {
          area.action.type = "uri";
          area.action.uri = `tel:${area.action.phoneNumber}`;
        }
      });
      payload.next_action = nextAction;

      try {
        this.isLoading = true;
        if (this.isEditing) {
          payload.id = this.$route.params.menuID;
          await richMenuApi.updateRichMenu(this.$route.params.menuID, payload);

          this.$swal("修改成功", "", "success");
        } else {
          await richMenuApi.createRichMenu(payload);

          this.$swal("新增成功", "", "success");
        }

        this.$router.push({
          name: "RichMenu",
          query: this.routeQuery,
        });
      } catch (error) {
        console.error(error.response.data.message);

        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: error.response.data.message,
        });

        if (
          error.response &&
          error.response.status === 422 &&
          error.response.data.message
        ) {
          this.validationErrors = error.response.data.message;
        }
      }
      this.isLoading = false;
    },
    async showConfirmSwal(action) {
      if (!action) return true;

      let confirmTitle, confirmMessage;
      switch (action) {
        case "set-schedule":
          confirmTitle = "預約上線";
          confirmMessage = `
            請問確定將${this.menu.title}預約上線？<br/>
            上線時間到之後，選單將自動生效。
          `;
          break;
        case "publish":
          confirmTitle = "立即上線";
          confirmMessage = `
            請問確定將${this.menu.title}立即上線？<br/>
            上線後，選單將自動生效。
          `;

          break;
      }

      const result = await this.$swal({
        title: confirmTitle,
        html: confirmMessage,
        type: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "取消",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "確認",
        reverseButtons: true,
      });

      return result.value;
    },
    customSave(areas) {
      this.menu.content.areas = areas;
      this.isCustoming = false;
    },
    handleCancel() {
      this.$router.push({
        name: "RichMenu",
        query: this.routeQuery,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  opacity: 0.5;
}

.image-selector {
  .image {
    position: relative;
    width: 30%;
    button {
      position: absolute;
      top: -1rem;
      right: -1rem;
    }
  }
  .selector {
    width: 30%;
  }
}

#modal-template {
  .template {
    width: 24%;
    button {
      height: 100%;
      width: 100%;
      border-width: 1px !important;
      position: relative;

      .action {
        position: absolute;
        border-width: 1px !important;
      }
    }
    &.template-big {
      button {
        aspect-ratio: 3 / 2;
      }
    }

    &.template-small {
      button {
        aspect-ratio: 3 / 1;
      }
    }
  }
}

.card-header {
  .collapsed .fa::before {
    content: "\f054";
  }
  .not-collapsed .fa::before {
    content: "\f078";
  }
}
</style>
